<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <!-- Multiple Column -->
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.configuration')} / ${$t('menu.agentes')}`">
        <v-card-text>
          <!--<v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'agentes-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>-->

          <!--<v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.name_contact"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
                <v-switch
                  v-model="model.active"
                  :label="$t('lbl.active')"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="model.tipo_afiliado_id"
                  :items="typeAfiliados"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.typeAfiliado')"
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-textarea
                  v-model="model.description"
                  :label="$t('lbl.description')"
                  outlined
                  rows="3"
                  row-height="25"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.prefijo_number_contact"
                  :label="$t('register.codePais')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.number_contact"
                  outlined
                  dense
                  :label="$t('register.noTelefo')"
                  type="number"
                  min="1"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="7"
              >
                <v-text-field
                  v-model="model.email_contact"
                  outlined
                  dense
                  :label="$t('lbl.email')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="model.address"
                  :label="$t('lbl.address')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.city"
                  :label="$t('lbl.city')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.state"
                  :label="$t('lbl.state')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.zip"
                  :label="$t('lbl.zip')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model="model.country"
                  :label="$t('lbl.country')"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <p class="font-weight-semibold text--primary">
                  {{ $t('register.titleFact') }}
                </p>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.name_fiscal_facturation"
                  outlined
                  dense
                  :label="$t('register.nameFact')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.cif_nif_facturation"
                  outlined
                  dense
                  :label="$t('register.cifNif')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.direction_facturation"
                  outlined
                  dense
                  :label="$t('register.address')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.code_postal_facturation"
                  outlined
                  dense
                  :label="$t('register.codePostal')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.localidad_facturation"
                  outlined
                  dense
                  :label="$t('register.locality')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="model.country_facturation"
                  outlined
                  dense
                  :label="$t('register.country')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.prefijo_number_facturation"
                  outlined
                  dense
                  :label="$t('register.codePais')"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.number_facturation"
                  outlined
                  dense
                  :label="$t('register.noTelefo')"
                  type="number"
                  min="1"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.email_facturation"
                  outlined
                  dense
                  :label="$t('register.emailPerson')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>-->
          <v-stepper
            v-model="activeStep"
            class="custom-header"
          >
            <!-- Header -->
            <v-stepper-header>
              <!-- Header: Step 1 -->
              <v-stepper-step
                :complete="activeStep > 1"
                step="1"
                :rules="[() => stepValidationResult[1]]"
              >
                <div class="d-flex align-center">
                  <span
                    class="text-4xl font-weight-bold me-3"
                    :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
                  >01</span>
                  <div class="d-flex flex-column">
                    <span
                      class="text-sm font-weight-semibold"
                      :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
                    >{{ $t('register.titlePerson') }}</span>
                    <span
                      class="text-xs"
                      :class="stepValidationResult[1] ? 'text--secondary' : 'error--text'"
                    >{{
                      $t('register.subTitleAgente')
                    }}</span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 2 -->
              <v-stepper-step
                :complete="activeStep > 2"
                step="2"
                :rules="[() => stepValidationResult[2]]"
              >
                <div class="d-flex align-center">
                  <span
                    class="text-4xl font-weight-bold me-3"
                    :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
                  >02</span>
                  <div class="d-flex flex-column">
                    <span
                      class="text-sm font-weight-semibold"
                      :class="stepValidationResult[2] ? 'text--primary' : 'error--text'"
                    >{{ $t('register.titleFiscal') }}</span>
                    <span
                      class="text-xs"
                      :class="stepValidationResult[2] ? 'text--secondary' : 'error--text'"
                    >{{
                      $t('register.configInfo')
                    }}</span>
                  </div>
                </div>
              </v-stepper-step>

              <v-divider></v-divider>

              <!-- Header: Step 3 -->
              <v-stepper-step
                step="3"
                :rules="[() => stepValidationResult[3]]"
              >
                <div class="d-flex align-center">
                  <span
                    class="text-4xl font-weight-bold me-3"
                    :class="stepValidationResult[3] ? 'text--primary' : 'error--text'"
                  >03</span>
                  <div class="d-flex flex-column">
                    <span
                      class="text-sm font-weight-semibold"
                      :class="stepValidationResult[3] ? 'text--primary' : 'error--text'"
                    >{{ $t('register.titleBanco') }}</span>
                    <span
                      class="text-xs"
                      :class="stepValidationResult[3] ? 'text--secondary' : 'error--text'"
                    >{{
                      $t('register.dataAccount')
                    }}</span>
                  </div>
                </div>
              </v-stepper-step>
            </v-stepper-header>

            <!-- Stepper Items -->
            <v-stepper-items>
              <!-- Stepper Content: Step 1 -->
              <v-stepper-content step="1">
                <v-form
                  ref="step1Form"
                  class="pb-6 pt-2"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    ></v-col>
                    <h3 class="ml-5">
                      {{ $t('register.subtitlePerson') }}
                    </h3>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.name_contact"
                        :label="$t('lbl.name')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('lbl.name')"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.apellidos_contact"
                        :label="$t('lbl.apellidos')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('lbl.apellidos')"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.email_contact"
                        :label="$t('register.emailPerson')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="emailRules"
                        :placeholder="$t('register.emailPerson')"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="model.prefijo_number_contact"
                        :label="$t('register.codePais')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('register.codePais')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="model.number_contact"
                        :label="$t('register.noTelefo')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="phoneRules"
                        :placeholder="$t('register.noTelefo')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <h3 class="pt-5">
                        {{ $t('register.typeAgente') }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-switch
                        v-model="model.active"
                        :label="$t('lbl.active')"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-select
                        v-model="model.tipo_afiliado_id"
                        :items="typeAfiliados"
                        item-text="name"
                        item-value="id"
                        :label="$t('lbl.typeAfiliado')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        class="pt-3"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="codeUnique"
                        :label="$t('lbl.code')"
                        outlined
                        dense
                        hide-details="auto"
                        :placeholder="$t('lbl.code')"
                        disabled
                        class="pt-3"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-row>
                        <v-col
                          v-for="(product, i) in products"
                          :key="i"
                          cols="12"
                          md="2"
                          class="text-center"
                        >
                          <v-checkbox
                            v-model="nom_tipo_products_id"
                            :value="product.id"
                            hide-details="auto"
                            color="primary"
                            :prepend-icon="product.icon"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>

                <div class="d-flex justify-end">
                  <v-btn
                    color="primary"
                    class="me-2"
                    @click="handleStepValidation(1)"
                  >
                    {{ $t('btn.next') }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <!-- Stepper Content: Step 2 -->
              <v-stepper-content step="2">
                <v-form
                  ref="step2Form"
                  class="pb-6 pt-2 multi-col-validation"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                      class="pt-5"
                    >
                      <h3 class="pt-5">
                        {{ $t('register.infoAgente') }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-switch
                        v-model="model.is_person"
                        :label="$t('register.person')"
                        hide-details
                        @change="isPerson"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row v-if="!model.is_person">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.name_comercial_agente"
                        :label="$t('register.nameComercial')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('register.nameComercial')"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.site_web"
                        :label="$t('register.siteWebCompany')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="urlRules"
                        :placeholder="$t('register.siteWebCompany')"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                      class="pt-5"
                    >
                      <h3 class="pt-5">
                        {{ $t('register.titleFiscal') }}
                      </h3>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-switch
                        v-model="model.idem_comercial"
                        :label="$t('register.idemComercial')"
                        hide-details
                        @change="idemComercial"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.name_fiscal_facturation"
                        :label="$t('register.nameLegal')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('register.nameLegal')"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-select
                        v-model="model.tipo_fiscal"
                        :items="tipos_fiscal"
                        :label="$t('register.tipoFiscal')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="model.cif_nif_facturation"
                        :label="$t('register.idFiscal')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('register.idFiscal')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>

                <div class="d-flex justify-space-between">
                  <v-btn
                    outlined
                    @click="activeStep = 1"
                  >
                    {{ $t('btn.preview') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="handleStepValidation(2)"
                  >
                    {{ $t('btn.next') }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <!-- Stepper Content: Step 3 -->
              <v-stepper-content step="3">
                <v-form
                  ref="step3Form"
                  class="pb-6 pt-2 multi-col-validation"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    ></v-col>
                    <h3 class="ml-5">
                      {{ $t('register.titleBanco') }}
                    </h3>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.no_cuenta_bancaria"
                        :label="$t('register.noBanco')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('register.noBanco')"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-select
                        v-model="model.tipo_cuenta_bancaria"
                        :items="tipos_cuentas_bancarias"
                        :label="$t('register.tipoBanco')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-select
                        v-model="model.moneda"
                        :items="monedas"
                        :label="$t('register.moneda')"
                        outlined
                        dense
                        item-text="code"
                        item-value="code"
                        hide-details="auto"
                        :rules="textRequiered"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.name_banco"
                        :label="$t('register.nameBanco')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('register.nameBanco')"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="model.swift"
                        :label="$t('register.codeSWIFT')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="swiftRules"
                        :placeholder="$t('register.codeSWIFT')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="model.code_sucursal"
                        :label="$t('register.codeSucursal')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('register.codeSucursal')"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="model.direction_facturation"
                        :label="$t('register.address')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('register.address')"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="model.localidad_facturation"
                        :label="$t('register.locality')"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                        :placeholder="$t('register.locality')"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-select
                        v-model="model.country_facturation"
                        :items="countries"
                        :label="$t('register.country')"
                        item-text="name"
                        item-value="name"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="textRequiered"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>

                <!-- Action Buttons -->
                <div class="d-flex justify-space-between">
                  <v-btn
                    outlined
                    @click="activeStep = 2"
                  >
                    {{ $t('btn.preview') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    :loading="loading"
                    @click="handleStepValidation(3)"
                  >
                    {{ $t('btn.complete') }}
                  </v-btn>
                </div>

                <!-- <v-snackbar v-model="isFormSubmittedSnackbarVisible">
                  Form Submitted Successfully.
                </v-snackbar> -->
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col
      cols="12"
      md="4"
    ></v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneOff,
  mdiCarBack,
  mdiBed,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneOff,
        mdiCarBack,
        mdiBed,
      },
      model: {
        name_fiscal_facturation: null,
        active: true,
        is_person: false,
      },
      permisos: [],
      loading: false,
      paisUser: {},
      typeAfiliados: [],
      tipos_fiscal: [],
      tipos_cuentas_bancarias: [],
      monedas: [],
      countries: [],
      products: [],
      nom_tipo_products_id: [],
      activeStep: 1,
      step1Form: null,
      step2Form: null,
      step3Form: null,
      stepValidationResult: {
        1: true,
        2: true,
        3: true,
      },
      isFormSubmittedSnackbarVisible: false,
      validators: {
        required: true,
      },
      userData: {},
      textRequiered: [v => !!v || 'Campo requerido'],
      emailRules: [v => !!v || 'Campo requerido', v => /.+@.+/.test(v) || 'E-mail debe ser válido'],
      urlRules: [v => this.isURL(v) || 'URL no es válida'],
      phoneRules: [v => !!v || 'Campo requerido', v => Number.isInteger(Number(v)) || 'Debe ser solo números'],
      swiftRules: [
        v => !!v || 'Campo requerido',

        // v => v.length <= 8 || 'Swift no válido',
        v => /[0-9a-zA-Z]{8}/.test(v) || 'Swift no válido',
      ],

      user: {},
      codeUnique: null,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('agente-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
  },
  created() {
    this.getTypeAfiliado()
    this.axios
      .get('https://ipapi.co/json')
      .then(res => {
        this.paisUser = res.data

        // eslint-disable-next-line no-empty
        if (sessionStorage.getItem('agente-id') !== null) {
        } else {
          // this.model.data_country_contact = this.paisUser
          this.model.prefijo_number_contact = this.paisUser.country_calling_code

          // this.model.number_contact = this.paisUser.country_calling_code
          this.model.country = this.paisUser.country_name
        }
      })
      // eslint-disable-next-line no-return-assign
      .finally(() => {
        this.getCountries()
        this.isLoading = false
      })

    this.tipos_fiscal.push('NIT')
    this.tipos_fiscal.push('NIF')
    this.tipos_fiscal.push('RTN')
    this.tipos_fiscal.push('RUC')

    this.tipos_cuentas_bancarias.push('Ahorros')
    this.tipos_cuentas_bancarias.push('Corriente')

    this.profile()
    this.loadPermisos()
    this.getItem()
  },
  methods: {
    isURL(str) {
      let url

      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        url = new URL(str)
      } catch (_) {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    autocompletarWeb() {
      if (this.model.site_web) {
        if (this.model.site_web.length === 1) {
          this.model.site_web = `https://${this.model.site_web}`
        }
      }
    },
    getCodeUnique() {
      this.axios
        .post(
          'afiliados/code_unique',
          { id: sessionStorage.getItem('user-register-id') },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.codeUnique = res.data.data.code
          }
        })

      /* .finally(() => {
          this.isLoading = false
        }) */
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          this.user = response.data.data
          this.getProducts()
        })
        .catch(error => console.log(error))
    },
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          if (this.user.company_id) {
            if (this.user.company.products.length > 0) {
              result.forEach(element => {
                if (this.user.company.products.includes(element.slug)) {
                  if (element.slug === 'cars' || element.slug === 'hotels' || element.slug === 'vuelos') {
                    this.products.push(element)
                  }
                }
              })
            }
          } else {
            this.products = res.data.data
          }
        })
    },
    getItem() {
      if (sessionStorage.getItem('agente-id') !== null) {
        const id = sessionStorage.getItem('agente-id')
        this.axios
          .get(`agentes/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.nom_tipo_products_id !== null) {
              this.nom_tipo_products_id = this.model.nom_tipo_products_id
              this.model.idem_comercial = this.model.is_person
            }
            if (this.model.code_unique) {
              this.codeUnique = this.model.code_unique
            } else {
              this.getCodeUnique()
            }
          })
      } else {
        this.getCodeUnique()
      }
    },
    getCountries() {
      this.axios
        .get('country_list?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.countries = res.data.data
          this.countries.forEach(element => {
            if (this.paisUser.country_code) {
              if (element.code === this.paisUser.country_code) {
                if (!this.model.country_facturation) {
                  this.model.country_facturation = element.name
                }
              }
            }

            /* if (element.currencies) {
              element.currencies.forEach(mmm => {
                if (!this.monedas.includes(mmm.code)) {
                  this.monedas.push(mmm.code)
                }
              })
            } */
          })
        })
        .finally(() => {
          this.getCurrencies()
        })

      /* this.axios.get('https://restcountries.com/v2/all?fields=name,currencies,alpha2Code').then(res => {
        // console.log(res.data)
        this.countries = res.data
        this.countries.forEach(element => {
          if (element.alpha2Code === this.paisUser.country_code) {
            this.model.country_facturation = element.name
          }
          const mone = element.currencies
          if (mone) {
            mone.forEach(mmm => {
              if (!this.monedas.includes(mmm.code)) {
                this.monedas.push(mmm.code)
              }
            })
          }
        })
      }) */
    },
    getCurrencies() {
      this.axios.get('currency_list?per_page=10000&active=1').then(res => {
        this.monedas = res.data.data
      })
    },
    loadPermisos() {
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(per => {
          this.permisos = per.data.data
        })
    },
    getTypeAfiliado() {
      this.axios
        .get(`tipo_afiliado?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const rest = res.data.data
          rest.forEach(element => {
            if (element.slug !== 'propios') {
              this.typeAfiliados.push(element)
            }
          })
        })
    },
    save() {
      this.loading = true
      this.model.perfil_slug = sessionStorage.getItem('perfil')
      this.model.nom_tipo_products_id = this.nom_tipo_products_id
      this.model.codeUnique = this.codeUnique
      if (sessionStorage.getItem('agente-id') === null) {
        this.axios
          .post('agentes', this.model, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            if (response.data.success === false) {
              if (response.data.data.status === 401) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else if (response.data.data.status === 402) {
                this.$toast.error(this.$t('msg.userExist', { email: this.model.email_contact }))
              }
            } else {
              this.$toast.info(this.$t('msg.sendEmailAgente', { name: this.model.name_fiscal_facturation }))
              sessionStorage.removeItem('agente-id')
              this.$router.push({ name: 'agentes-list' })
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      } else {
        this.axios
          .put(`agentes/${this.model.id}`, this.model, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            if (response.data.success === false) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else {
              sessionStorage.removeItem('agente-id')
              this.$router.push({ name: 'agentes-list' })
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))
      }
    },
    handleStepValidation(step) {
      // Get Form templateRef of Step from argument
      if (step === 1) {
        const isStepValid = this.$refs.step1Form.validate()
        if (isStepValid) {
          this.activeStep += 1
        }
      } else if (step === 2) {
        const isStepValid = this.$refs.step2Form.validate()
        if (isStepValid) {
          this.activeStep += 1
        }
      } else if (step === 3) {
        const isStepValid = this.$refs.step3Form.validate()
        if (isStepValid) {
          this.save()
        }
      }
    },
    isPerson() {
      if (this.model.is_person) {
        this.model.idem_comercial = true
        this.idemComercial()
      }
    },
    idemComercial() {
      if (this.model.idem_comercial) {
        if (this.model.is_person) {
          this.model.name_fiscal_facturation = `${this.model.name_contact} ${this.model.apellidos_contact}`
        } else {
          this.model.name_fiscal_facturation = this.model.name_comercial_agente
        }
      } else {
        this.model.name_fiscal_facturation = ''
      }
    },
  },
}
</script>
